import { Box, Container } from '@mui/material';
import { useLocalStorage } from '@uidotdev/usehooks';
import * as date from 'date-fns';
import { useState } from 'react';
import { RouteObject } from 'react-router-dom';
import useSWR from 'swr';
import { PageSpinner, Video } from '~/components';
import { ChartCard } from '~/components/ChartCard';
import { PageHeader } from '~/components/PageHeader';
import { useFarm } from '~/hooks/useFarm';
import { applyStandardDeviation } from '~/utils/stddev';

export const feederRoute: RouteObject = {
  path: '/feeder',
  element: <Feeder />,
}

const defaultFrom = date.subWeeks(new Date(), 6)
const defaultTo = date.addDays(new Date(), 1)

function Feeder() {
  const { farm } = useFarm()

  const [from, setFrom] = useLocalStorage('settings.from', date.formatISO(defaultFrom, { representation: 'date' }))
  const [to, setTo] = useLocalStorage('settings.to', date.formatISO(defaultTo, { representation: 'date' }))
  const [params, setParams] = useState({ from, to })

  const { data: feederData = [], isLoading: isLoadingFeeder } = useSWR(
    `/farms/${farm?.id}/insights/feeder?dateFrom=${params.from}&dateTo=${params.to}`)

  const { data: boutData = [], isLoading: isLoadingBouts } = useSWR(
    `/farms/${farm?.id}/insights/feeding-bout?dateFrom=${params.from}&dateTo=${params.to}`)

  const { data: media } = useSWR(
    `/farms/${farm?.id}/media/feeder/${params.to.replace(/-/g, '')}`)

  const birdCount = feederData.map((d: any) => {
    return {
      datetime: date.addHours(date.parseISO(d.date), d.hour),
      date: d.date,
      hour: d.hour,
      avg: d.birdCountAvg,
      min: d.birdCountMin,
      max: d.birdCountMax,
      stddev: applyStandardDeviation(d.birdCountAvg, d.birdCountStdDev),
    }
  })

  const isFeeding = feederData.map((d: any) => {
    return {
      datetime: date.addHours(date.parseISO(d.date), d.hour),
      date: d.date,
      hour: d.hour,
      avg: d.isFeedingAvg,
      min: d.isFeedingMin,
      max: d.isFeedingMax,
      stddev: applyStandardDeviation(d.isFeedingAvg, d.isFeedingStdDev),
    }
  })

  const percentageFeeding = feederData.map((d: any) => {
    return {
      datetime: date.addHours(date.parseISO(d.date), d.hour),
      date: d.date,
      hour: d.hour,
      avg: d.percentageFeedingAvg,
      min: d.percentageFeedingMin,
      max: d.percentageFeedingMax,
      stddev: applyStandardDeviation(d.percentageFeedingAvg, d.percentageFeedingStdDev)
    }
  })

  const birdWeight = boutData.map((d: any) => {
    return {
      datetime: date.addHours(date.parseISO(d.date), d.hour),
      date: d.date,
      hour: d.hour,
      avg: d.weightAvg,
      min: d.weightMin,
      max: d.weightMax,
      stddev: applyStandardDeviation(d.weightAvg, d.weightStdDev),
    }
  })

  const handleApply = () => {
    setParams({ from, to })
  }

  return (
    <Container maxWidth={false} sx={{ pb: '24px' }}>
      <PageHeader title="Feeder" from={from} to={to} setFrom={setFrom} setTo={setTo} handleApply={handleApply} />
      {(isLoadingFeeder || isLoadingBouts) && <PageSpinner />}
      {(!isLoadingFeeder && !isLoadingBouts) && (
        <Box display={"grid"} gridTemplateColumns={"repeat(auto-fill, minmax(500px, 700px))"} gap={2}>
          <Video height='375px' width='100%' src={media.url} />
          <ChartCard title="Bird Count" data={birdCount} />
          <ChartCard title="Is Feeding" data={isFeeding} />
          <ChartCard title="% Feeding" data={percentageFeeding} />
          <ChartCard title="Bird Weight" data={birdWeight} />
        </Box>
      )}
    </Container>
  )
}
